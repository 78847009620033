<template>
  <v-chip
    small
    v-bind="$attrs"
    outlined
    :to="{
      name: 'DepartmentDetail',
      params: { id: value.id },
    }"
  >
    <Icon left small>mdi-account-details</Icon>
    {{ value.description }}
  </v-chip>
</template>

<script>
export default {
  name: "DepartmentChip",
  props: {
    value: { type: Object, default: null },
  },
};
</script>
