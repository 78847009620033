<template>
  <v-card v-if="isVisible">
    <v-system-bar>Schule</v-system-bar>
    <v-list dense>
      <SchoolClassItem
        label="Klasse"
        v-if="info.schoolClass"
        :value="info.schoolClass"
      ></SchoolClassItem>

      <v-list-item v-if="info.duties" title="Ämtli">
        <v-list-item-avatar>
          <v-icon>mdi-check</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-subtitle> Ämtli </v-list-item-subtitle>
          <v-list-item-title>
            <v-chip-group column>
              <v-chip
                class="pt-0 mt-0"
                outlined
                v-for="(duty, index) in info.duties"
                :key="index"
                :value="duty"
              >
                {{ duty.description }}
              </v-chip>
            </v-chip-group>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <ListItem v-if="info.phoneSchool" title="Telefon Schule">
        <PhoneNumber :value="info.phoneSchool" />
      </ListItem>
      <ListItem v-if="info.code" title="Kürzel">
        <StaticText :value="info.code" />
      </ListItem>
      <ListItem v-if="info.samAccountName" title="Kontoname kurz">
        <StaticText :value="info.samAccountName" />
      </ListItem>
      <ListItem v-if="info.userPrincipalName" title="Kontoname lang">
        <StaticText :value="info.userPrincipalName" />
      </ListItem>
      <ListItem v-if="info.departmentHeadOf" title="Fachschaftsvorsitz">
        <v-chip-group column>
          <DepartmentChip
            v-for="(department, index) in info.departmentHeadOf"
            :key="index"
            :value="department"
          />
        </v-chip-group>
      </ListItem>
      <ListItem v-if="info.departments" title="Fachschaften">
        <v-chip-group column>
          <DepartmentChip
            v-for="(department, index) in info.departments"
            :key="index"
            :value="department"
          />
        </v-chip-group>
      </ListItem>
      <ListItem v-if="info.classTeacherOf" title="Klassenlehrperson von">
        <v-chip-group column>
          <SchoolClassChip
            v-for="(schoolClass, index) in info.classTeacherOf"
            :key="index"
            :value="schoolClass"
          />
        </v-chip-group>
      </ListItem>
    </v-list>
  </v-card>
</template>
<script>
import DepartmentChip from "common/components/DepartmentChip.vue";
import ListItem from "@/components/ListItem.vue";
import PhoneNumber from "common/components/Person/PhoneNumber.vue";
import SchoolClassChip from "common/components/SchoolClassChip.vue";
import SchoolClassItem from "common/components/SchoolClassItem.vue";
import StaticText from "common/components/StaticText.vue";
export default {
  name: "ContactSchool",
  components: {
    DepartmentChip,
    ListItem,
    PhoneNumber,
    SchoolClassChip,
    SchoolClassItem,
    StaticText,
  },
  props: {
    person: { type: Object, default: null },
  },
  data() {
    return {
      info: null,
    };
  },
  computed: {
    hasPerson() {
      return this.person && this.person.id;
    },
    isVisible() {
      return this.hasPerson && this.info;
    },
  },
  watch: {
    person() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.info = null;
      if (!this.hasPerson) return;
      this.info = await this.apiGet({
        resource: "person/schoolinfo",
        id: this.person.id,
      });
    },
  },
};
</script>
